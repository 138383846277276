.app-cart{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    /* float: right; */
    background-color: rgba(238,242,245);
    overflow-x: hidden;
    transition: 0.2s;
}
.cart-body{
    height: 90%;
    overflow-y: scroll;
    /* position: relative; */
}

.web-cart-body{
    height: calc(100vh - 210px - 74px);
    overflow-x: hidden;
    overflow-y: scroll;
}

.empty-cart-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    width:200px;
    height: 200px;
    padding:4px;
    /* border: 1px solid lightgray; */
    text-align: center;  
}
img{
    object-fit: contain; 
    width: 100%;
    height: 100%;
}
.cart-overlay{
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.cart-head{
    position: fixed;
    z-index:3;    
}

.cart-head-btn{
    height: 70px;
    width: 70px;
    border-right: 1px solid rgba(0,0,0,.2);
}

.cart-item{
    /* padding-left:40px; */
    height: 100px;
    border-bottom: 1px solid #dee6ec;
}
.cart-item p {
    font-size: 0.7rem;
}
.cart-item-block{
    height: 80px;
}
.cart-item-img{
    /* text-align: center; */
    height: 40px;
    width: 40px;
}

.item-bar-btn:hover{
    background-color: #ddd;
    color: black;
}



@media screen and (min-width: 768px) {
    .cart-checkout{
        position: relative;
    }
  } 


.cart-discount-code{
    border: 1px solid rgba(0,0,0,.2);
    height: 30px;
}
.info-bar-wrap{
    height: 50%;
}
.info-bar{
    /* display:block; */
    /* bottom: 0; */
    height: 40px;
    justify-content: space-between;
}

.info-bar-gp{
    width: 20%;
}

.info-total{
    margin-left: 5px;
}

.info-qty{
    margin: 10px;
    height: 25px;
    width: 25px;
    font-size: 0.75rem;
    border-radius: 30px;
}
.info-cart-btn{
    width: 20%;
}

.cart-item-card{
    border-radius: 0.375rem;
    transition: 0.1s;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    width: 100%;
    background-color: white;
}
.cart-item-card:active{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cart-item-card-disabled:active{
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
}
.cart-item-detail p{
    color: grey;
}
.cart-item-btn-gp{
    width: 80px;
    height: 100%;
}
.cart-item-btn-gp button{
    /* all: unset; */
    margin-top: unset;
    padding: unset;
    border-radius: 50%;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.cart-item-quantity{
    font-size: 1rem;
}
.cart-item-btn{
    background-color: transparent;
    box-shadow: unset;
    display: grid;
    align-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.cart-item-btn:active{
    border: 0;
    outline: 0;
}
.cart-checkout-btn{
    min-width:100px;
}

.cart-checkout{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: #eef2f5;
    transition: 0.2s;
    min-height: 180px;
    width: 100%;
    border-top: 1px solid rgb(190, 190, 190);
}
.price-overview{
    display: grid;
    grid-template-columns: 30% 1fr auto;
    column-gap: 10px;
    row-gap: 12px;
    padding: 10px;
}
/* .price-overview h5{
    height: 30px;
} */
.price-overview .title{
    text-align: right;
}
.sized-box-checkout{
    height: 130px;
}
.cart-price-wrap{
    margin-left: 10px;
    text-align: left;
    min-width: 70px;
    max-width: 50%;
}
.cart-checkout-btn{
    width: 100%;
    height: 70px;
    z-index: 1;
    border-radius: unset;
    position: relative;
    /* bottom: 0; */
    background-color: var(--addons-submit-btn);
    font-size: 1.15rem;
    transition: 0.2s;
    margin-top: unset;
}
.cart-checkout-btn h4{
    color: white;
    font-size: 1.15rem;
    font-weight: 600;
}