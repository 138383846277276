.app-menu{
    display: block;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
}

.menu-head{
    /* min-height: 70px; */
    align-items: center;
    height: var(--head-bar-height);
    position: fixed;
    z-index:1;
}
.menu-img{
    /* margin-top: var(--head-bar-height); */
    height: 200px;
    position: relative;
}
/* .menu-img:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 90%,rgba(255,255,255,1) 100%); 
} */
@media only screen and (min-width: 300px) and (max-width: 413px){
    .menu-head-title{
        font-size: 0.85rem;
        font-weight: 500;
    }
    h4{
        font-size: 1.15rem;
        line-height: 1.3rem;
    }
    h5{
        font-size: 1.05rem;
        line-height: 1.2rem;
    }
    h6{
        font-size: .9rem;
        line-height: 1.1rem;
    }
}
@media only screen and (min-width: 414px) and (max-width: 575px){ 
    .menu-head-title{
        font-size: 1rem;
        font-weight: 500;
    }
    h4{
        font-size: 1.15rem;
        line-height: 1.3rem;
    }
    h5{
        font-size: 1rem;
        line-height: 1.2rem;
    }
    h6{
        font-size: .9rem;
        line-height: 1.1rem;
    }
  }
@media only screen and (min-width: 576px) and (max-width: 767px){
    .menu-head-title{
        font-size: 1.25rem;
        font-weight: 500;
    }
    /* h4{
        font-size: 1.125rem;
        line-height: 1.125rem;
    }
    h5{
        font-size: 1rem;
        line-height: 1rem;
    }
    h6{
        font-size: 0.875rem;
        line-height: 1rem;
    } */
}

.cart-quantity{
    /* background-color: #fc8f8ce3; */
    background-color: #fc908c;
    height: 17px;
    width: 17px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    position: absolute;
    right: 12px;
    top: -8px;
}

.app-bar-btn{
    width:70px;
    display: grid;
    justify-items: center;
    align-self: center;
    /* margin-bottom: 5px; */
    cursor: pointer;
    position: relative;
    /* color: white; */
}
.app-bar-btn span{
    font-size: 0.8rem;
}

.menu-body{
    /* height: 100vh;
    overflow-y: scroll; */
    /* position: absolute; */
    z-index: 0;
    padding-top: var(--head-bar-height);
}

/* category head */
.category-head{
    height: 150px;
}
.category-bar{
    height: 30%;
    background-color: #ff9782;
}
.category-bar-img{
    min-height: 30%;
    background-color: #adadad8f;
}
.category-bar h5{
    color: white;
    font-weight: 500;
}
.category-head-btn{
    width: 40px;
    height: 40px;
}
/* category body */

.category-item{
    min-height: 50px;
    /* height: auto; */
    /* max-height: 120px; */
    border-bottom: 1px solid rgba(0,0,0,.2);
    align-items: center;
}
.category-item-info{
    width: 60%;
}
.category-item-price{
    width: 20%;
}
.category-item-img{
    height: 75px;
    width: 75px;
    margin-left: 10px;
}
.category-bar-arrow-btn{
    line-height: unset;
    cursor: pointer;
}

.menu-category{
    position: relative;

    border-radius: 20px;
    /* background-color: pink; */
    background-color: white;
}
.secondary-name{
    font-size: 0.8rem!important;
}
.item-child.secondary-name{
    font-size: 0.7rem!important;
    text-align: left;
}
.option-child.secondary-name{
    font-size: 0.7rem!important;
    text-align: left;
}