
@media only screen and (min-width: 320px) { 
  .app-body{
    background-color: red;
  }
}

@media only screen and (min-width: 414px) { 
  .app-body{
    background-color: yellow;
  }
}

@media only screen and (min-width: 576px) { 
  .app-body{
    background-color: orange;
  }

}

@media only screen and (min-width: 768px) { 
  .app-root{
    max-width: 768px; 
    margin-right: auto;
    margin-left: auto;
  }
  .app-body{
    background-color: yellowgreen;
  }
  /* h1{
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  h2{
      font-size: 1.5rem;
      line-height: 2rem;
  }
  h3{
      font-size: 1.25rem;
      line-height: 1.75rem;
  }
  h4{
      font-size: 1.125rem;
      line-height: 1.5rem;
  }
  h5{
      font-size: 1rem;
      line-height: 1.5rem;
  }
  h6{
      font-size: .75rem;
      line-height: 1.5rem;
  }
  p{
      font-size: .5rem;
  } */
}
.app-root{
  min-width: 320px;
  max-width: 768px;
  width: 100vw;
  height: 100%;
  background-color: #eef2f5;
  overflow: hidden;
  
}

.app-body{
  min-height: 100vh;
  /* position: relative; */
  /* transition: 0.2s; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}

.overlay{
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #eef2f5;
  overflow-x: hidden;
  transition: 0.2s;
}


.row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.item{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid black;
  height: 200px;
}
.restaurant-closed{
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: white;
}
.restaurant-closed h6{
  line-height: 2rem;
  text-align: center;
}
.restaurant-closed-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    /* border: 1px solid lightgray; */
    text-align: center;

}