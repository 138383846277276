@import url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{ /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    font-family: "Noto Sans TC", "Lato", sans-serif;
    /* font-weight: 500!important; */
}
/* chinese */
@font-face {
    font-family: "Noto Sans TC", sans-serif;
    src: url(https://fonts.googleapis.com/earlyaccess/notosanstc.css);
    unicode-range: U+4E00-9FFF;
}
/* english */
@font-face{
    font-family: 'Lato', sans-serif;
    src: url(https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap);
    unicode-range: U+00-024F;
}
/* *{

    -webkit-touch-callout: none; 
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
} */

*::-webkit-scrollbar {
    display: none;
}
*{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
input[type="radio"] {
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;    /* Firefox */
    -ms-appearance: checkbox;     /* not currently supported */
}
input[type='text'],
textarea{
    font-size: 16px;
}
body{
    color:  #373d4a;
}
input, .StripeElement{
    max-width: unset !important;
}

:root{
    --head-bar-height: 60px;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}

@-webkit-keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
    }
}
@keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

.mat-button{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    color: rgba(0,0,0,.54);
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.mat-button:hover{
    background: rgba(0,0,0,.06);
}
.mat-button:active{
    background: rgba(0,0,0,.12);
}
.mat-warn{
    background-color: #d32f2f;
    color: white;
}
.mat-button-disable{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    background-color: rgba(0,0,0,.06);
    box-shadow: none;
    color: rgba(0,0,0,.26);
    font-size: 0.9rem;
    font-weight: 500;
}
.mat-disable{
    background-color: rgba(0,0,0,.06);
    box-shadow: none;
    color: rgba(0,0,0,.26);
}
.mat-primary{
    background-color: #1a73e8;
    color: white;
}
.mat-primary:hover{
    background-color: #1765cc;
    color: white;
}
.mat-primary:active{
    background-color: #1457b0;
    color: white;
}
.mat-warn:hover{
    background-color: #ba2929;
}
.mat-warn:active{
    background-color: #a02424;
}
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
.modal-content {
    /* background-color: #fefefe; */
    margin: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    /* border: 1px solid #888; */
    width: 80%;
  }
.dialog-backdrop {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.dialog-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    margin-top: 30%;
    /* left: 25%;
    top: 25%; */
    top:0;
    left: 0;
    right: 0;
    margin-left: auto; 
    margin-right: auto; 
    /* width: 300px; */
    max-width: 600px;
    overflow: auto; /* Enable scroll if needed */
  }
  @media screen and (max-width: 960px) {
    .dialog-container{
      max-width: 600px;
    }
  } 
  @media screen and (max-width: 633px) {
    .dialog-container{
      max-width: 500px;
    }
  }
  @media screen and (max-width: 575px) {
    .dialog-container{
      max-width: 90%;
    }
  } 
.dialog {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
.dialog-head{
    padding: 1.3rem;
    width: 100%;
    height: 100%;
}
.dialog-warning{
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}

.dialog-warning :is(h6,h5){
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}
.dialog-warning h6{
    font-size: 0.9rem;
}

.dialog-content{
    padding: 1.3rem;
}

.dialog-content label{
    color: rgba(0,0,0,.54);
    font-size: 0.85rem;
}
.error-text{
    color: #c62828;
    font-size: 0.75rem;
    line-height: 1rem;
    position: relative;
}
.error-text i{
    font-size: 0.95rem;
}
/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-head .head-btn{
    border-radius: 50%;
    height: 40px;
    width: 40px;
}
.center, .head-btn, .btn{
    display: grid;
    place-items: center;
}
.touch, .head-btn, .btn\:circle, .btn{
    cursor: pointer;
}
.untouch{
    cursor: unset;
}
.shadow{
    box-shadow: 0 8px 10px rgb(0 0 0 / 1%), 0 3px 7px -1px rgb(0 0 0 / 15%);
}
.invisible{
    visibility: hidden;
}
.shadow\:light{ 
    box-shadow: 15px 15px 35px -8px rgba(81,81,81,0.53);
}
.head-btn, .btn{
    min-height: 50px;
    min-width: 50px;
    font-size: 1.25rem;
}
.head-bar{
    height: 60px;
    height: var(--head-bar-height);
    width: 100%;
}
.btn\:circle{
    border-radius: 50%;
    font-size: 1.25rem;
}

.border\:left, .border\:all{
    border-left: 1px solid rgba(0,0,0,.2);
}
.border\:right, .border\:all{
    border-right: 1px solid rgba(0,0,0,.2);
}
.border\:top, .border\:all{
    border-top: 1px solid rgba(0,0,0,.2);
}
.border\:bottom, .border\:all{
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.c\:row{
    display: flex;
    flex-direction: column;
}
.c\:between{
    justify-content:space-between;
}
.c\:evenly{
    justify-content:space-evenly;
}
.c\:around{
    justify-content: space-around;
}
.c\:col{
    display: flex;
}
.c\:center{
    justify-content: center;
}
.c\:left{
    justify-content: flex-start;
}
.c\:right{
    justify-content: flex-end;
}
.justify-content-start{
    justify-content: flex-start;
}
.justify-content-between{
    justify-content: space-between;
}
.justify-content-around{
    justify-content: space-around;
}
.justify-content-end{
    justify-content: flex-end;
}
.justify-content-center{
    justify-content: center;
}
.justify-self-start{
    justify-self: flex-start;
}
.justify-self-center{
    justify-self: center;
}
.justify-self-end{
    justify-self: flex-end;
}
.align-content-between{
    align-content: space-between;
}
.align-items-start{
    align-items: flex-start;
}
.align-items-end{
    align-items: flex-end;
}
.align-items-center{
    align-items: center;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-end{
    align-self: flex-end;
}
.align-self-center{
    align-self: center;
}

.c\:middle{
    align-items: center;
}
.flex\:1{
    flex: 1 1;
}
.flex\:3{
    flex: 3 1;
}
.float\:left{
    float: left;
}
.float\:right{
    float: right;
}


.gap\:xs{
    grid-gap: .375rem;
    gap: .375rem;
}
.gap\:sm{
    grid-gap: .75rem;
    gap: .75rem;
}
.gap\:md{
    grid-gap: 1rem;
    gap: 1rem;
}
.gap\:lg{
    grid-gap: 1.25rem;
    gap: 1.25rem;
}

.mb\:xs, .my\:xs, .m\:xs{
    margin-bottom: .375rem;
}
.mb\:sm, .my\:sm, .m\:sm{
    margin-bottom: .75rem;
}
.mb\:md, .my\:md, .m\:md{
    margin-bottom: 1rem;
}
.mb\:lg, .my\:lg, .m\:lg{
    margin-bottom: 1.25rem;
}

.mt\:xs, .my\:xs, .m\:xs{
    margin-top: .375rem;
}
.mt\:sm, .my\:sm, .m\:sm{
    margin-top: .75rem;
}
.mt\:md, .my\:md, .m\:md{
    margin-top: 1rem;
}
.mt\:lg, .my\:lg, .m\:lg{
    margin-top: 1.25rem;
}

.mr\:xs, .mx\:xs, .m\:xs{
    margin-right: .375rem;
}
.mr\:sm, .mx\:sm, .m\:sm{
    margin-right: .75rem;
}
.mr\:md, .mx\:md, .m\:md{
    margin-right: 1rem;
}
.mr\:lg, .mx\:lg, .m\:lg{
    margin-right: 1.25rem;
}

.ml\:xs, .mx\:xs, .m\:xs{
    margin-left: .375rem;
}
.ml\:sm, .mx\:sm, .m\:sm{
    margin-left: .75rem;
}
.ml\:md, .mx\:md, .m\:md{
    margin-left: 1rem;
}
.ml\:lg, .mx\:lg, .m\:lg{
    margin-left: 1.25rem;
}

.pb\:xs, .py\:xs, .p\:xs{
    padding-bottom: .375rem;
}
.pb\:sm, .py\:sm, .p\:sm{
    padding-bottom: .75rem;
}
.pb\:md, .py\:md, .p\:md{
    padding-bottom: 1rem;
}
.pb\:lg, .py\:lg, .p\:lg{
    padding-bottom: 1.25rem;
}

.pt\:xs, .py\:xs, .p\:xs{
    padding-top: .375rem;
}
.pt\:sm, .py\:sm, .p\:sm{
    padding-top: .75rem;
}
.pt\:md, .py\:md, .p\:md{
    padding-top: 1rem;
}
.pt\:lg, .py\:lg, .p\:lg{
    padding-top: 1.25rem;
}

.pl\:xs, .px\:xs, .p\:xs{
    padding-left: .375rem;
}
.pl\:sm, .px\:sm, .p\:sm{
    padding-left: .75rem;
}
.pl\:md, .px\:md, .p\:md{
    padding-left: 1rem;
}
.pl\:lg, .px\:lg, .p\:lg{
    padding-left: 1.25rem;
}

.pr\:xs, .px\:xs, .p\:xs{
    padding-right: .375rem;
}
.pr\:sm, .px\:sm, .p\:sm{
    padding-right: .75rem;
}
.pr\:md, .px\:md, .p\:md{
    padding-right: 1rem;
}
.pr\:lg, .px\:lg, .p\:lg{
    padding-right: 1.25rem;
}

.rt\:xs, .r\:xs{
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}
.rt\:sm, .r\:sm{
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
}
.rt\:md, .r\:md{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.rt\:lg, .r\:lg{
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}

.rb\:xs, .r\:xs{
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
}
.rb\:sm, .r\:sm{
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
}
.rb\:md, .r\:md{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.rb\:lg, .r\:lg{
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

.text\:center{
    vertical-align: center;
}
.text-align-left{
    text-align: left;
}
.text-align-center{
    text-align: center;
}

h1{
    word-wrap: break-word;
    font-size: 2.25rem;
    line-height: 3rem;
}
h2{
    word-wrap: break-word;
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h3{
    word-wrap: break-word;
    font-size: 1.5rem;
    line-height: 2rem;
}
h4{
    word-wrap: break-word;
    font-size: 1.25rem;
    line-height: 1.75rem;
}
h5{
    word-wrap: break-word;
    font-size: 1.125rem;
    line-height: 1.75rem;
}
h6{
    word-wrap: break-word;
    font-size: 1rem;
    line-height: 1.5rem;
}
p{
    word-wrap: break-word;
    font-size: .75rem;
}

.f\:xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.f\:sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.f\:md{
    font-size: 1rem;
    line-height: 1.5rem;
}
.f\:lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.f\:xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.f\:2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}
.f\:3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.f\:300{
    font-weight: 300;
}
.f\:400{
    font-weight: 400;
}
.f\:500{
    font-weight: 500;
}

.f\:600{
    font-weight: 600;
}
.f\:700{
    font-weight: 700;
}
.f\:bold{
    font-weight: bold;
}
.f\:grey{
    color: #555555;
}
.f\:black{
    color: rgb(30, 30, 30);
}
.f\:light-grey{
    color: #858585;
}
.f\:white{
    color: white;
}
.f\:orange{
    color: #ff504a;
}
.f\:icon {
    font-size: 1rem;
}
.bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.bg\:head-bar{
    background-color: rgba(255,255,255);
}
.bg\:overlay{
    background-color: rgba(0,0,0,.5);
}
.bg\:trans{
    background-color: transparent;
}
.bg\:black{
    background-color: black;
}
.bg\:grey{
    background-color: #eef2f5;
}
.bg\:white{
    background-color: white;
}
.bg\:pink{
    background-color: pink;
}
.bg\:green{
    background-color: green;
}
.bg\:yellow{
    background-color: #ffaa32;
}
.bg\:orange{
    background-color: #ff504a;
}

.min-h\:vh{
    min-height: 100vh;
}
.h\:full{
    height: 100%;
}
.h\:min{
    min-height: 100vh;
}
.h\:auto{
    height: auto;
}
.h\:inherit{
    height: inherit;
}
.w\:max{
    max-width: 768px;
}
.min-w\:vw{
    width: 100vw;
}
.w\:min{
    min-width: 100vw;
}
.w\:full{
    width: 100%;
}
.w\:auto{
    width: auto;
}
.w\:inherit{
    width: inherit;
}
.w\:4\/5{
    width: 80%;
}
.w\:3\/4{
    width: 75%;
}
.w\:1\/2{
    width: 50%;
}
.w\:1\/3{
    width: 66.666667%;
}
.w\:1\/4{
    width: 25%;
}
.max-w\:1\/2{
    max-width: 50%;
}
.max-w\:1\/4{
    max-width: 25%;
}
h1, h2, h3, h4, h5, h6, p{
    color: #555555;
    /* color: #2f2f2f; */
    font-weight: 500;
    margin: 0;
}

a{
    text-decoration: none;
}
a:link {
    color: transparent;
}
/* visited link */
a:visited {
    color: transparent;
}
/* mouse over link */
a:hover {
    color: transparent;
}
/* selected link */
a:active {
    color: transparent;
}

@media only screen and (min-width: 320px) { 
  .app-body{
    background-color: red;
  }
}

@media only screen and (min-width: 414px) { 
  .app-body{
    background-color: yellow;
  }
}

@media only screen and (min-width: 576px) { 
  .app-body{
    background-color: orange;
  }

}

@media only screen and (min-width: 768px) { 
  .app-root{
    max-width: 768px; 
    margin-right: auto;
    margin-left: auto;
  }
  .app-body{
    background-color: yellowgreen;
  }
  /* h1{
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  h2{
      font-size: 1.5rem;
      line-height: 2rem;
  }
  h3{
      font-size: 1.25rem;
      line-height: 1.75rem;
  }
  h4{
      font-size: 1.125rem;
      line-height: 1.5rem;
  }
  h5{
      font-size: 1rem;
      line-height: 1.5rem;
  }
  h6{
      font-size: .75rem;
      line-height: 1.5rem;
  }
  p{
      font-size: .5rem;
  } */
}
.app-root{
  min-width: 320px;
  max-width: 768px;
  width: 100vw;
  height: 100%;
  background-color: #eef2f5;
  overflow: hidden;
  
}

.app-body{
  min-height: 100vh;
  /* position: relative; */
  /* transition: 0.2s; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;
}

.overlay{
  height: 100%;
  width: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #eef2f5;
  overflow-x: hidden;
  transition: 0.2s;
}


.row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.item{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid black;
  height: 200px;
}
.restaurant-closed{
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: white;
}
.restaurant-closed h6{
  line-height: 2rem;
  text-align: center;
}
.restaurant-closed-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    /* border: 1px solid lightgray; */
    text-align: center;

}
.app-addons{
    height: 100%;
    width: 0%;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background-color: #eef2f5;
    /* background-color: yellow; */
    transition: 0.2s;
    overflow-y: scroll;
    
    /* -webkit-overflow-scrolling: auto */
  }
  .addons-head{
      position: fixed;
      z-index:3;    
  }
  
  .addons-head-btn{
      min-height: 50px;
      min-width: 50px;
      border-right: 1px solid rgba(0,0,0,.2);
  }
  .web-addons-body{
    height: calc(100vh - 75px - 75px);
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .addons-img{
      display: block;
      height: 200px;
  }
  .sized-box{
      height: 70px;
  }
  
  .addons-choices-box{
      min-height: 40px;
  }
  
  .addons-choice:last-child{
      /* border: 1px solid rgba(0,0,0,.2); */
      border-bottom: 0px solid rgba(0,0,0,.2);
  }
  .addons-choice{
      border-bottom: 1px solid rgba(0,0,0,.2);
      vertical-align: middle;
      height: 40px;
  }
  
  .addons-special{
      min-height: 80px;
      border: 1px solid #ccc
  }
  .addons-bar{
      height: 50px;
      width: 90%;
      justify-content: space-between;
  }
  .addons-btn-gp{
      width: 30%;
      justify-content: space-evenly;
  }
  .addons-bar-btn{
      height: 40px;
      width: 40px;
      border-radius: 50px;
  }
  .addons-bar-btn:hover{
      background-color: rgb(167, 165, 165);
      color: black;
  }
  .addons-total{
      width: 40%;
  }
  .addons-add-btn{
      width: 30%;
  }
  /* .addons-submit-btn{
      width: 100px;
      height: 50px;
      background-color: transparent;
      box-shadow: unset;
      color: #ff504a;
      margin-top: unset;
  } */
  .addons-btn{
      height: 50px;
      width: 50px;
      background-color: transparent;
      box-shadow: unset;
      color: #ff504a;
      margin-top: unset !important;
      border-radius: 50%;
  }
  :root {
      --item-parent: #ffbbb8;
      --item-parent-font: #555555;
      --item-parent-standard-item: #a0a0a0;
      --item-parent-standard-item-font: white;
      --item-parent-selected: #ffebea;
      --item-parent-selecting: #ffbbb8;
      --item-parent-selecting-font: #555555;
      --item-parent-selected-font: #555555;
      --item-parent-unselect: #a0a0a0;
      --item-parent-unselect-font: white;
      --item-child: white;
      --option-parent: #a0a0a0;
      /* --option-parent: #ffcda3; */
      --option-parent-selected-font: #555555;
      --option-parent-selected: #ffddc1;
      --addons-submit-btn: #fd817c;
      --item-error: #ff6464;
      --item-parent-weight: 600;
      --item-parent-selected-weight: 600;
      --item-parent-selecting-weight: 600;
  
      --option-parent-height: 30px;
      --item-parent-height: 45px;
      --no-option: #ff927c;
      --addons-btn-margin: 0.4rem;
    }
  .addons-body button{
      margin-top: 0.4rem;
      margin-top: var(--addons-btn-margin);
  }
  .option-parent{
      padding-left: 0.6rem;
      height: 45px;
  }
  .item-error{
      border: #ff6464 3px solid;
      border: var(--item-error) 3px solid;
  }
  .no-option-btn{
      width: 100%;
      height: 45px;
      /* height: var(--option-parent-height); */
      background-color: #ff927c;
      background-color: var(--no-option);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      /* padding-left: 0.6rem; */
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .no-option-btn h6{
      /* font-size: 1.15rem; */
      color: white;
      color: var(--item-parent-unselect-font);
      font-weight: 600;
      font-weight: var(--item-parent-weight);
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent{
      width: 100%;
      background-color: #ffbbb8;
      background-color: var(--item-parent);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      padding: 0 0.6rem;
      text-align: unset;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent h5{
      font-size: 1.15rem;
      color: #555555;
      color: var(--item-parent-font);
      font-weight: 600;
      font-weight: var(--item-parent-weight);
      text-transform: none;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  
  .addons-item-parent-unselect{
      width: 100%;
      background-color: #a0a0a0;
      background-color: var(--item-parent-unselect);
      /* background-color: grey; */
      font-weight: 600;
      font-size: 1.15rem;
      text-overflow: ellipsis;
      font-weight: 600;
      padding-left: 0.6rem;
      text-align: unset;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-unselect h5{
      font-size: 1.15rem;
      color: white;
      color: var(--item-parent-unselect-font);
      font-weight: 600;
      font-weight: var(--item-parent-weight);
      text-transform: none;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  
  .addons-item-parent-selected, .addons-item-parent-selecting{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #ffebea;
      background-color: var(--item-parent-selected);
      padding: 0.6rem; 
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-selected h5,
  .addons-item-parent-selecting h5{
      font-size: 1.15rem;
      font-weight: 600;
      text-transform: none;
      /* text-overflow: ellipsis; */
      /* overflow: hidden; */
  }
  .addons-item-parent-selected{
      /* text-overflow: ellipsis;
      overflow-x: hidden; */
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #ffebea;
      background-color: var(--item-parent-selected);
  }
  .addons-item-parent-selecting{
      /* text-overflow: ellipsis;
      overflow-x: hidden; */
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #ffbbb8;
      background-color: var(--item-parent-selecting);
  }
  .addons-item-parent-selected h5{
      color: #555555;
      color: var(--item-parent-selected-font);
  }
  .addons-item-parent-selecting h5{
      color: #555555;
      color: var(--item-parent-selecting-font);
  }
  .addons-item-parent-standard-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #a0a0a0;
      background-color: var(--item-parent-standard-item);
      text-overflow: ellipsis;
      padding: 0 0.6rem; 
  }
  .addons-item-parent-standard-item h5{
      font-size: 1.15rem;
      font-weight: 600;
      color: white;
      color: var(--item-parent-standard-item-font);
  }
  
  .addons-item-parent-selected-standard-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #ffebea;
      background-color: var(--item-parent-selected);
      text-overflow: ellipsis;
      padding: 0 0.6rem; 
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-item-parent-selected-standard-item h5{
      font-size: 1.15rem;
      color: #555555;
      color: var(--item-parent-selected-font);
  }
  .addons-option-parent{
      align-self: flex-end;
      width: 100%;
      background-color: #a0a0a0;
      background-color: var(--option-parent);
      font-weight: 600;
      font-size: 1.15rem;
      text-align: unset;
      text-overflow: ellipsis;
  }
  .addons-option-parent h5{
      font-size: 1.15rem;
      color: white;
      font-weight: 600;
      font-weight: var(--item-parent-weight);
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-option-parent-selected{
      align-self: flex-end;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      width: 100%;
      font-size: 1.15rem;
      background-color: #ffddc1;
      background-color: var(--option-parent-selected);
      text-overflow: ellipsis;
      /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
  }
  .addons-option-parent-selected h5{
      font-size: 1.15rem;
      font-weight: 600;
      color: #555555;
      color: var(--option-parent-selected-font);
  }
  
  .addons-item-parent-selected div{
      /* color: white; */
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
  }
  .selected-item-info{
      width: 80%;
      justify-content: center;
  }
  .selected-item-name{
      text-align: left;
      overflow-x: hidden;
      text-overflow: ellipsis;
  }
  .addons-item-parent:active{
      background-color: #fa5c56;
  }

  .standard-item{
    /* padding-left: 0.6rem;
    padding-right: 0.6rem; */
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    align-items: center;
    height: 50px;
    margin-top: 0.4rem;
    margin-top: var(--addons-btn-margin);
    padding: 0 0.6rem;
    /* margin-top: 10px;
    margin-bottom: 5px; */
  }
  .standard-item h5{
    font-size: 1.15rem;
    font-weight: 500;
  }
  
  .addones-item-child-grid{
      /* display: grid;
      grid-template-columns: 49% 49%; 
      grid-column-gap: 2%; */
      /* justify-self: center; */
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      /* flex: 2 1 auto; */
      /* row-gap: 2%; */
      /* column-gap: var(--addons-btn-margin); */
      justify-content: space-between;
      /* column-gap: 0.3rem; */
  }
  .addones-option-child-grid{
      align-self: flex-end;
      width: 100%;
      /* display: grid; 
      grid-template-columns: 49% 49%;
      grid-column-gap: 0.3rem; */
      display: flex;
      flex-direction: row;
      /* flex-flow: column wrap; */
      flex-wrap: wrap;
      justify-content: space-between;
  }
  .addons-item-child, .addons-item-child-selected{
      /* border: #fd817c 1px solid; */
      /* white-space: nowrap; */
      /* text-overflow: ellipsis; */
      background-color: white;
      background-color: var(--item-child);
      color: black;
      font-weight: 400;
      font-size: 0.75rem;
      text-align: center;
      padding: 0.6rem;
      justify-content: space-between;
      flex-basis: 48%;
      /* flex-grow: 2; */
      /* flex-grow: 100%; */
      max-width: 100%;
      text-transform: none;
  }
  .addons-item-child.small-box, .addons-item-child-selected.small-box{
    flex-basis: auto;
    margin-right: 5px;
  }
  .addons-item-child-selected{
      border: #ffbbb8 3px solid;
      border: var(--item-parent-selecting) 3px solid;
  }
  
  
  .addons-item-child-name {
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      /* word-break: break-all; */
      /* width: 90vw; */
  }
  .addons-option-child, .addons-option-child-selected{
      /* border: #fd817c 1px solid; */
      white-space: nowrap;
      text-overflow: ellipsis;
      background-color: white;
      background-color: var(--item-child);
      overflow: hidden;
      color: black;
      font-weight: 400;
      font-size: 0.75rem;
      text-align: center;
      padding: 0.6rem; 
      flex-basis: 48%;
      /* flex-grow: 2; */
      max-width: 100%;
  }
  
  
  .addons-option-child-selected{
      border: #ffddc1 3px solid;
      border: var(--option-parent-selected) 3px solid;
  }
  
  .addons-option-child-name{
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
  }
  .addons-sized-box{
      height: 90px;
  }
  .addons-submit-btn-wrap{
      position: fixed;
      z-index: 10;
      border-radius: unset;
      background-color: #fd817c;
      background-color: var(--addons-submit-btn);
      /* background-color: yellow; */
      font-size: 1.15rem;
      overflow: hidden;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 70px;
      transition: 0.2s;
      letter-spacing: 0.025em;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer; 
  }
  .addons-submit-btn-wrap:active {
    color: #fff;
    cursor: pointer;
    background-color: #ff8b67;
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
  .addons-submit-btn{
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: unset;
      background-color: #fd817c;
      background-color: var(--addons-submit-btn);
      font-size: 1.15rem;
      margin-top: 0!important;
  }
  .addons-submit-btn h4{
      color: white;
      font-weight: 600;
  }
  .addons-price{
      position: absolute;
      right: 0;
      top: 25px;
      padding-right: 10px;
  }
  /* .addons-submit-btn-bg{
      z-index: 1;
      overflow: hidden;
      position: fixed;
      height: 50px;
      right: 0;
      bottom: 0;
      background-color: var(--addons-submit-btn);
      transition: 0.2s;
  } */

.trash-wrapper{
    display: flex;
    justify-content: flex-end;
}
.trash-wrapper.child-option{
    width: 100%;
    align-self: flex-end;
}
.trash{
    background-color: #fa3b31;
    color: white;
    display: flex;
    align-items: center;
    box-shadow: none;
}
.trash.child-option{
    height: 45px;
}
.addons-option-parent-selected div:nth-child(2){
    margin: 0 auto;
}
.option-button-wrapper{
    display: flex;
    justify-content: flex-end;
}
.option-stack-wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 90%;
    margin-left: auto;
}
.option-stack-wrapper > div{
    width: 100%;
}
.no-option-btn-wrap{
    margin-right: 5px;
}
.addones-item-child-grid.small-grid-container{
    justify-content: flex-start;
}
.app-menu{
    display: block;
    height: 100vh;
    overflow-y: scroll;
    position: relative;
}

.menu-head{
    /* min-height: 70px; */
    align-items: center;
    height: var(--head-bar-height);
    position: fixed;
    z-index:1;
}
.menu-img{
    /* margin-top: var(--head-bar-height); */
    height: 200px;
    position: relative;
}
/* .menu-img:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
    height: 200px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 90%,rgba(255,255,255,1) 100%); 
} */
@media only screen and (min-width: 300px) and (max-width: 413px){
    .menu-head-title{
        font-size: 0.85rem;
        font-weight: 500;
    }
    h4{
        font-size: 1.15rem;
        line-height: 1.3rem;
    }
    h5{
        font-size: 1.05rem;
        line-height: 1.2rem;
    }
    h6{
        font-size: .9rem;
        line-height: 1.1rem;
    }
}
@media only screen and (min-width: 414px) and (max-width: 575px){ 
    .menu-head-title{
        font-size: 1rem;
        font-weight: 500;
    }
    h4{
        font-size: 1.15rem;
        line-height: 1.3rem;
    }
    h5{
        font-size: 1rem;
        line-height: 1.2rem;
    }
    h6{
        font-size: .9rem;
        line-height: 1.1rem;
    }
  }
@media only screen and (min-width: 576px) and (max-width: 767px){
    .menu-head-title{
        font-size: 1.25rem;
        font-weight: 500;
    }
    /* h4{
        font-size: 1.125rem;
        line-height: 1.125rem;
    }
    h5{
        font-size: 1rem;
        line-height: 1rem;
    }
    h6{
        font-size: 0.875rem;
        line-height: 1rem;
    } */
}

.cart-quantity{
    /* background-color: #fc8f8ce3; */
    background-color: #fc908c;
    height: 17px;
    width: 17px;
    display: grid;
    place-items: center;
    border-radius: 100%;
    position: absolute;
    right: 12px;
    top: -8px;
}

.app-bar-btn{
    width:70px;
    display: grid;
    justify-items: center;
    align-self: center;
    /* margin-bottom: 5px; */
    cursor: pointer;
    position: relative;
    /* color: white; */
}
.app-bar-btn span{
    font-size: 0.8rem;
}

.menu-body{
    /* height: 100vh;
    overflow-y: scroll; */
    /* position: absolute; */
    z-index: 0;
    padding-top: var(--head-bar-height);
}

/* category head */
.category-head{
    height: 150px;
}
.category-bar{
    height: 30%;
    background-color: #ff9782;
}
.category-bar-img{
    min-height: 30%;
    background-color: #adadad8f;
}
.category-bar h5{
    color: white;
    font-weight: 500;
}
.category-head-btn{
    width: 40px;
    height: 40px;
}
/* category body */

.category-item{
    min-height: 50px;
    /* height: auto; */
    /* max-height: 120px; */
    border-bottom: 1px solid rgba(0,0,0,.2);
    align-items: center;
}
.category-item-info{
    width: 60%;
}
.category-item-price{
    width: 20%;
}
.category-item-img{
    height: 75px;
    width: 75px;
    margin-left: 10px;
}
.category-bar-arrow-btn{
    line-height: unset;
    cursor: pointer;
}

.menu-category{
    position: relative;

    border-radius: 20px;
    /* background-color: pink; */
    background-color: white;
}
.secondary-name{
    font-size: 0.8rem!important;
}
.item-child.secondary-name{
    font-size: 0.7rem!important;
    text-align: left;
}
.option-child.secondary-name{
    font-size: 0.7rem!important;
    text-align: left;
}
.app-cart{
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    /* float: right; */
    background-color: rgba(238,242,245);
    overflow-x: hidden;
    transition: 0.2s;
}
.cart-body{
    height: 90%;
    overflow-y: scroll;
    /* position: relative; */
}

.web-cart-body{
    height: calc(100vh - 210px - 74px);
    overflow-x: hidden;
    overflow-y: scroll;
}

.empty-cart-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    width:200px;
    height: 200px;
    padding:4px;
    /* border: 1px solid lightgray; */
    text-align: center;  
}
img{
    object-fit: contain; 
    width: 100%;
    height: 100%;
}
.cart-overlay{
    position: absolute;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.cart-head{
    position: fixed;
    z-index:3;    
}

.cart-head-btn{
    height: 70px;
    width: 70px;
    border-right: 1px solid rgba(0,0,0,.2);
}

.cart-item{
    /* padding-left:40px; */
    height: 100px;
    border-bottom: 1px solid #dee6ec;
}
.cart-item p {
    font-size: 0.7rem;
}
.cart-item-block{
    height: 80px;
}
.cart-item-img{
    /* text-align: center; */
    height: 40px;
    width: 40px;
}

.item-bar-btn:hover{
    background-color: #ddd;
    color: black;
}



@media screen and (min-width: 768px) {
    .cart-checkout{
        position: relative;
    }
  } 


.cart-discount-code{
    border: 1px solid rgba(0,0,0,.2);
    height: 30px;
}
.info-bar-wrap{
    height: 50%;
}
.info-bar{
    /* display:block; */
    /* bottom: 0; */
    height: 40px;
    justify-content: space-between;
}

.info-bar-gp{
    width: 20%;
}

.info-total{
    margin-left: 5px;
}

.info-qty{
    margin: 10px;
    height: 25px;
    width: 25px;
    font-size: 0.75rem;
    border-radius: 30px;
}
.info-cart-btn{
    width: 20%;
}

.cart-item-card{
    border-radius: 0.375rem;
    transition: 0.1s;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    width: 100%;
    background-color: white;
}
.cart-item-card:active{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cart-item-card-disabled:active{
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
}
.cart-item-detail p{
    color: grey;
}
.cart-item-btn-gp{
    width: 80px;
    height: 100%;
}
.cart-item-btn-gp button{
    /* all: unset; */
    margin-top: unset;
    padding: unset;
    border-radius: 50%;
    -ms-user-select: none;
        user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.cart-item-quantity{
    font-size: 1rem;
}
.cart-item-btn{
    background-color: transparent;
    box-shadow: unset;
    display: grid;
    align-content: center;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.cart-item-btn:active{
    border: 0;
    outline: 0;
}
.cart-checkout-btn{
    min-width:100px;
}

.cart-checkout{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: #eef2f5;
    transition: 0.2s;
    min-height: 180px;
    width: 100%;
    border-top: 1px solid rgb(190, 190, 190);
}
.price-overview{
    display: grid;
    grid-template-columns: 30% 1fr auto;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    grid-row-gap: 12px;
    row-gap: 12px;
    padding: 10px;
}
/* .price-overview h5{
    height: 30px;
} */
.price-overview .title{
    text-align: right;
}
.sized-box-checkout{
    height: 130px;
}
.cart-price-wrap{
    margin-left: 10px;
    text-align: left;
    min-width: 70px;
    max-width: 50%;
}
.cart-checkout-btn{
    width: 100%;
    height: 70px;
    z-index: 1;
    border-radius: unset;
    position: relative;
    /* bottom: 0; */
    background-color: var(--addons-submit-btn);
    font-size: 1.15rem;
    transition: 0.2s;
    margin-top: unset;
}
.cart-checkout-btn h4{
    color: white;
    font-size: 1.15rem;
    font-weight: 600;
}
.app-checkout{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  bottom: 0;
  right: 0;
  /* float: right; */
  background-color: rgba(238,242,245);
  overflow-x: hidden;
  transition: 0.2s;
}

.checkout-head{
  position: fixed;
  z-index:4; 
}
.checkout-body{
  height: 100vh;
  overflow-y: scroll;
}
.web-checkout-body{
  height: calc(100vh - 75px - 80px);
}
/* .checkout-info-box{
  min-height: 80px;
} */
.checkout-edit-btn{
  color: #c7cdd6;
  border-color: #c7cdd6;
  min-height: 40px;
  min-width: 40px;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 50px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  /* background-color: #6772e5; */
  background-color: #fa6d42;
  cursor: pointer;
  text-decoration: none;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:active {
  color: #fff;
  cursor: pointer;
  background-color: #ff8b67;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
button::-moz-selection,
button::selection
/* @media only screen and (min-width: 768px) {
  button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  }
} */

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::-webkit-input-placeholder {
  color: #aab7c4;
}

input:-ms-input-placeholder {
  color: #aab7c4;
}

input::placeholder {
  color: #aab7c4;
}
input{
  height: 40px;
  border: 1px solid #aab7c4;
  border-radius: 4px;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}
.checkout-btn-wrap{
  position: absolute;
  height: 100px;
  z-index: 1;
  border-radius: unset;
  /* background-color: var(--addons-submit-btn); */
  font-size: 1.15rem;
  overflow: hidden;
  bottom: 0;
  right: 0;
  height: 70px;
  transition: 0.2s;
}
.error-msg{
  color: #fa755a;
}
#web-terms-confirmation input{
  height: unset;
  border: unset;
  border-radius: unset;
  margin-right: 10px;
}
#web-terms-confirmation input:focus{
  box-shadow: unset;
}
#web-terms-confirmation a{
  color: black;
  text-decoration: underline;
}
/* .app-order-review{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 4;
    overflow-x: hidden;
    transition: 0.2s;
    background-color: rgba(0,0,0, 0.5);
    backdrop-filter: blur(8px);
} */
.app-order-review{
    height: 100vh;
    width: 0%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 4;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    transition: 0.2s;
    background-color: rgba(0,0,0, 0.5);
    -webkit-backdrop-filter: blur(8px);
            backdrop-filter: blur(8px);
}

.order-review-body{
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: calc(100vh - 75px - 145px);
    /* position: relative; */
}
/* .review-detail{
    overflow-y: scroll;
} */

.order-review-head .close-btn{
    border-radius: 50%;
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
}
.order-review-head{
    min-height: 50px;
    max-height: 70px;
    position: fixed;
    z-index: 4;
}
.order-review-overview{
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background-color: #eef2f5;
    transition: 0.2s;
    height: 130px;
    width: 100%;
    border-top: 1px solid rgb(190, 190, 190);
}
.order-review-price-wrap{
    margin-left: 30px;
    text-align: left;
    min-width: 70px;
    max-width: 50%;
}
/* .info-bar{

    position: fixed;

    bottom: 0;
    height: 40px;
    width: 90%;
    z-index: 10;
    justify-content: space-between;
} */

.info-bar-gp{
    /* width: 70%; */
}

.info-total{
    margin-left: 5px;
}

.info-qty{
    margin: 10px;
    height: 25px;
    width: 25px;
    font-size: 0.75rem;
    border-radius: 30px;
}
.info-cart-btn{
    width: 30%;
}
.privacy{
    max-width:1000px;
    margin: auto;
    padding: 20px;
}
.privacy h1{
    margin: 20px 0;
    text-align: center;
    font-size: 2rem;
    color: black;
}
.privacy h2{
    margin: 30px 0;
    font-size: 1.5rem;
    color: black;
}
.privacy p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8rem;
    
}
.privacy li p{
    position: relative;
    left: 5px;
}
.privacy a{
    text-decoration: underline;
}
